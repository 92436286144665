export const findEntryByCode = (source, code) => {
    if (code && source != null) {
        const codex = source.findIndex((c) => {
            return c.isoCode === code;
        });
        return codex !== -1 ? source[codex] : undefined;
    }
    return undefined;
};
export const findStateByCodeAndCountryCode = (source, code, countryCode) => {
    if (code && countryCode && source != null) {
        const codex = source.findIndex((c) => {
            return c.isoCode === code && c.countryCode === countryCode;
        });
        return codex !== -1 ? source[codex] : undefined;
    }
    return undefined;
};
export const compare = (a, b) => {
    if (a.name < b.name)
        return -1;
    if (a.name > b.name)
        return 1;
    return 0;
};
